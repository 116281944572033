:root{
  --dark-blue: #233356;
  --light-blue: #a7d5f8;
  --background: #FFFFFF;
  /* --gold: #FFD700 */
  --gold: #D7BE69;
  --box-shadow: rgba(14, 39, 66, 0.08) 0px 1px 25px 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


