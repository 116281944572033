.wrapper{
    width: 100%;
    height: 100vh;
}

.card-holder{
    position: relative;
    margin: auto 50px;
    height: 100%;
    width: 100%;
}

.indicator{
    width: 100%;
    height: 50px;
    /* background-color: red; */
    position: absolute;
    top: 250px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicator > .dot{
    display: none;
    background-color:rgb(141, 141, 141);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 8px;
}

.indicator > .active{
    background-color:#233356;
}

.next_next{
    height: 40vh !important;
    transform: translateY(35px) translateX(40px);
    background: #24314d !important;
    display: none;
}


.next{
    display: none;
    height: 35vh !important;
    transform: translateY(55px) translateX(80px);
    background: #2b3957 !important;
}
.main_banner_wrapper{
    top: -230px;
    position: absolute;
        /* background: #1a191957; */
    /* background: #233356; */
    height: 50vh;
    width: 560px;
    /* margin: auto 50px; */
    padding: 60px;
    border-radius: 15px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
    


}

.banner_title{ 
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-size: 40pt;
    font-weight: 900;
    letter-spacing: 1.2px;
}



.main_banner{
    text-align: left;


}

.main_banner p{
    font-size: 17.3pt;
    font-weight: 200;
    line-height: 1.4em;
    font-family: 'Montserrat', sans-serif;
}

.main_banner_m p{
    font-size: 17.3pt;
    font-weight: 200;
    line-height: 1.4em;
    font-family: 'Montserrat', sans-serif;
}


.dark{
    /* background: "rgba(21,29,44)" */
    background: var(--dark-blue);
    color: #fff;
    padding: 0;
}

.light_200{
    color: var(--light-blue);
}

.primary-main{
    color: var(--dark-blue);
    font-size: 22px !important;
}

.title{
    font-family: 'Fira Sans Condensed', sans-serif;
    font-weight: 500;
    font-size: 39pt;
    
}

.os_content{
    font-family: 'Palanquin', sans-serif;
}

.center{
    text-align: center;
}

.fs_20{
    font-size: 20px !important;
}