.site_menu_wrapper{
    position: fixed;
    top: 0;
    background: rgb(21 29 44);
    /* background: var(--light-blue); */
    background: var(--background);
    color: var(--dark-blue);
    /* color: #DBdef7; */
    height: 60px;
    left: 0;
    right: 0;
    text-align: left;
    display: flex;
    z-index: 99999;
    /* box-shadow: var(--box-shadow); */
    /* border-bottom: 1px solid #e9e9e9; */
}

.icon{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

}
.site_name{
    height: 100%;
    width: 200px;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18pt;
    font-weight: bold;
    /* color: rgb(9, 241, 133); */
    /* color: #BDDEF7; */

}

.site_menu{
    position: absolute;
    right: 100px;
    bottom: 20px;
    /* background: green; */
    width: auto;

    
}

.site_menu ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.site_menu ul > li{
    display: inline;
    padding: 0 16px;
    cursor: pointer;
}