.service_card_wrapper{
    width: calc(300px - 32px);
    min-height: 400px;
    border-radius: 5px;
    padding: 24px;
    line-height: 1.7em;
    /* background: rgb(251, 250, 252); */
    background: var(--light-blue);
    /* color: #242424; */
    color: var(--dark-blue);
    /* box-shadow: 0px 8px 23px -1px #8d888873;
    line
     */
     box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    position: relative;
    font-family: 'Palanquin', sans-serif;
}

.service_card_wrapper::after{
    content: '';
    width: calc(300px - 72px);
    height: 3px;
    position: absolute;
    bottom: -13px;
    left: 36px;
    background: #646464;
    filter: blur(8px);
}

.scw_icon{
    width: 109px;
    height: 109px;
    border-radius: 50%;
    /* background: rgb(9, 241, 133); */
    background: var(--dark-blue);
    margin: 0 auto 15px auto;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scw_icon img{
    /* width: 60px; */
    color: #fff;
}
.scw_title{
    font-size: 13pt;
    font-weight: 500;
    margin: 0 0 16px 0;
    text-align: center;
}

.scw_desc{
    /* color: #646464; */
    font-size: 12.5pt;
}

.scw_learn_more{
    margin: 40px 0 10px 0;
}

/* service_card_holder_wrapper */
.service_card_holder_wrapper{
    /* background: blue; */
    margin: 142px auto;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 0 5px;
}   

.mt_30{
    margin-top: 30px;
}