:element {
    --primary-main: #233356
    --primary-light: #DBDEF7
}
.po_wrapper{
    width: 100%;
    margin: 150px 0 0 0;
    padding: 0px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 25px;
}

.po_info_content{
    /* background: blue; */
}

.po_info_content p{
    text-align: left;
}


.po_info_content > .info{
    color: #000000;
    font-size: 15pt;
    font-family: 'Palanquin', sans-serif !important;
    font-weight: 300;
    text-align: left;
    color: #233356;
    margin-top: -30px;

}

.lh_3{
    line-height: 1.7em;
}

.po_title{
    font-family: 'Fira Sans Condensed', sans-serif;
    font-weight: 900;
    font-size: 36pt;
}