.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  
  /* background: #BDDEF7; */
  background: #fff;
  /* background-color: #282c34; */
  /* background-image: url(./images/bg1.jpg); */
  /* background-image: url(./images/bg2.png); */
  /* background: hsla(206, 80%, 84%, 1);

background: linear-gradient(360deg, hsla(206, 80%, 84%, 1) 15%, hsla(206, 78%, 73%, 1) 38%, hsla(221, 42%, 51%, 1) 78%);

background: -moz-linear-gradient(360deg, hsla(206, 80%, 84%, 1) 15%, hsla(206, 78%, 73%, 1) 38%, hsla(221, 42%, 51%, 1) 78%);

background: -webkit-linear-gradient(360deg, hsla(206, 80%, 84%, 1) 15%, hsla(206, 78%, 73%, 1) 38%, hsla(221, 42%, 51%, 1) 78%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#B7DBF7", endColorstr="#84C1F0", GradientType=1 ); */
  background-size: cover;
  min-height: 100vh;
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */

}


.App-header > .rht-banner {
  position: absolute;
  right: 0;
  left: 50%;
  top: 60px;
  bottom: 0;
  width: "100%";
  /* background: red; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}



@media screen {
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.site_button:hover{
  background: var(--light-blue) !important;
  color: var(--dark-blue) !important;
  border-bottom: 5px solid var(--dark-blue);
  
}

.site_button{
  outline: none;
  border: none;
  text-align: left;
  background: #233356 !important;
  color: #BDDEF7 !important;
  min-width: 120px;
  padding: 12px 16px 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 13pt;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  /* transition: 1000ms ease-in-out; */
  border-bottom: 5px solid var(--dark-blue) !important;
}