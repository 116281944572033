.c_content {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.c_description {
    width: 90%;
    font-size: 15pt;
    font-family: 'Palanquin', sans-serif;
    font-weight: 300;
    text-align: left;
    color: #233356;
    margin-top: 0px;
}

.c_container {
    background: #f7f7f7;
    height: 150vh;
    margin-top: 150px;
    display: flex;
    align-items: center;
}




.button_holder {
    height: 40px;

}

